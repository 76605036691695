import React, { useEffect, useState } from "react"
import Head from "next/head"
import { Header } from "../header/Header"
import { Footer } from "../footer/Footer"
import { GlobalSettings, HeaderStyle, PageReference, PageThemeType, SanityImage } from "src/sanity/types"
import buildLink from "../../sanity/buildLink"
import { SettingsContext } from "./settingsContext"
import { Provider as BusProvider } from "react-bus"
import { OverwriteProvider } from "./overwriteContext"
import s from "./Page.module.scss"
import cn from "classnames"
import { urlFor } from "src/sanity/sanity"
import { slugify } from "src/templates/blog/blogHelper"
import { DocTypeMap } from "src/templates"
import { ExperimentsProvider } from "./experimentsContext"
import { initialize as initializeTextBalance } from "../../lib/text-balancer"
import RibbonModule from "src/modules/ribbon-module/RibbonModule"
import { languageMap } from "src/sanity/languages"
import Config from "src/Config"

interface PageProps {
    children: React.ReactNode
    pageTitle?: string
    title?: string
    description?: string
    globalSettings: GlobalSettings
    type?: string
    imageUrl?: string
    url?: string
    pageReferences?: PageReference[]
    headerStyle: HeaderStyle
    pageThemeType?: PageThemeType
    backgroundImage?: SanityImage
    docType?: keyof DocTypeMap
    noIndex?: boolean
    documentId?: string
}

export default function Page({
    url,
    type = "website",
    globalSettings,
    pageReferences,
    pageTitle,
    title,
    imageUrl,
    headerStyle,
    pageThemeType,
    backgroundImage,
    description,
    noIndex,
    docType,
    children,
    documentId,
}: PageProps) {
    const [secretWordToGoToSanity, setSecretWordToGoToSanity] = useState("")
    useEffect(() => {
        const checkIfSecretWordIsEntered = (e: KeyboardEvent) => {
            if (e.target instanceof Element && e?.target?.nodeName === "INPUT")
                return
            setSecretWordToGoToSanity((lastWord) => lastWord + e.key)
        }
        document.addEventListener("keyup", checkIfSecretWordIsEntered)

        return () => document.removeEventListener("keyup", checkIfSecretWordIsEntered)
    }, [])
    useEffect(() => {
        if ((secretWordToGoToSanity.endsWith("sanity") || secretWordToGoToSanity.endsWith("andreaserkonge")) && documentId) {
            const domain = (window.location.hostname.includes("local"))
                ? "http://localhost:3333"
                : (window.location.hostname.includes("2024h2-design-revamp") ? "https://lunar-website-studio-git-2024h2-design-revamp-lunar-web.vercel.app" : "https://lunar-website-studio.vercel.app")

            window.open(`${domain}/intent/edit/id=${documentId}`, "_blank")
        }
    }, [documentId, secretWordToGoToSanity])

    const alternatives = (pageReferences || []).map(({ lang, page }) => {
        const hrefLang = languageMap[lang]?.intlLocale || ""
        return {
            hrefLang,
            href: buildLink(page),
        }
    })

    if (url?.includes("banking-services")) {
        url = url.replace("/business/", "/")
    }

    useEffect(() => {
        try {
            initializeTextBalance()
        } catch (error) {
            //
        }
    }, [children])

    useEffect(() => {
        document.body.classList.add("segment-" + globalSettings?.segment)

        return () => document.body.classList.remove("segment-" + globalSettings?.segment)
    }, [globalSettings])

    const isFrontpage = docType === "frontpage" || url?.includes("frontpage") // Allow A/B tests for the frontpage to act as frontpage
    const isMoonriseSite = ["9fafd9da-e349-4062-bfa8-2890a0d32f76", "256d2eb5-a858-4148-ae79-042ac54c4fe3", "a1bf4edc-eadf-497e-afb7-8ecc6eac03d7", "47abb8ef-2b0b-41fc-ae22-8cc1215bfd73", "3b46e3d9-e42e-4c47-96da-257d0d93f762", "9a8547bb-190c-4405-a1e4-34a0559d44e9", "62bf4fed-d583-4eba-a16c-a2545b06286e",
        // , "39d4f483-f2a7-4863-ac31-e55be5798b47",
    ].includes(documentId?.replace("drafts.", ""))

    if (isMoonriseSite) {
        // Fix canonical URL for Moonrise sites
        url = url.replace(Config.BASE_URL, "https://moonrise.inc").replace(/en\/business\/moonrise-?/, "")
    }
    const hasNewsRibbon = globalSettings?.newsRibbon?.ribbonType && (!globalSettings.showOnlyNewsRibbonOnFrontpage || (globalSettings.showOnlyNewsRibbonOnFrontpage && isFrontpage))

    return (
        <BusProvider>
            <SettingsContext.Provider value={{ ...globalSettings }}>
                <OverwriteProvider>
                    <ExperimentsProvider url={url} segment={globalSettings?.segment} lang={globalSettings?.lang}>
                        {() => (
                            <div style={{
                                "backgroundImage": backgroundImage?.src ? `url("${backgroundImage.src}")` : undefined,
                            } as React.CSSProperties} className={
                                cn(
                                    {
                                        [s["dark-theme"]]: pageThemeType === "dark",
                                        [s["shareit-theme"]]: pageThemeType === "shareit",
                                        "shareit-theme": pageThemeType === "shareit",
                                        [s["has-background"]]: backgroundImage?.src,
                                        "segment-moonrise": isMoonriseSite,
                                    },
                                    "doc-" + docType,
                                    "segment-" + globalSettings.segment,

                                    url ? "path-" + slugify((new URL(url)).pathname) : undefined,
                                )
                            }>
                                <Head>
                                    <meta name="title" content={title} />
                                    <meta name="description" content={description} />
                                    <meta name="theme-color" content={globalSettings.segment === "private" ? "#ffffff" : "#000000"} />

                                    <title>{`${pageTitle}${!isMoonriseSite ? " - Lunar" : ""}`}</title>

                                    <link rel="icon" type="image/png" href={`${isMoonriseSite ? "/moonrise" : ""}/favicon-96x96.png`} sizes="96x96" />
                                    <link rel="icon" type="image/svg+xml" href={`${isMoonriseSite ? "/moonrise" : ""}/favicon.svg`} />
                                    {/* <link rel="shortcut icon" href={`${isMoonriseSite ? "/moonrise" : ""}/favicon.ico`} /> */}
                                    <link rel="apple-touch-icon" sizes="180x180" href={`${isMoonriseSite ? "/moonrise" : ""}/apple-touch-icon.png`} />
                                    <link rel="manifest" href={`${isMoonriseSite ? "/moonrise" : ""}/site.webmanifest`} />

                                    {/* If "url" is falsy then the page shouldn't have SEO tags. */}
                                    {url && (
                                        <>
                                            <meta property="og:description" content={description} />
                                            <meta property="og:title" content={pageTitle} />
                                            <meta property="og:type" content={type} />
                                            <meta property="og:url" content={url} />
                                            {imageUrl && (
                                                <meta property="og:image" content={imageUrl.includes("sanity.io") ? urlFor(imageUrl).width(1080).quality(60).url() : imageUrl} />
                                            )}

                                            <link rel="canonical" href={url} />
                                            {alternatives.map(({ href, hrefLang }, i) => <link key={i} rel="alternative" href={href} hrefLang={hrefLang} />)}
                                        </>
                                    )}
                                    {(noIndex || !url) && <meta name="robots" content="noindex" />}
                                    {isMoonriseSite && (
                                        <>
                                            <link href="https://fonts.googleapis.com/css2?family=Gabarito:wght@400..900&display=swap" rel="stylesheet" />
                                            <style>{`.coi-banner__accept { background-color: #9E45F7 !important; }`}</style>
                                        </>
                                    )}
                                </Head>
                                {hasNewsRibbon && (
                                    <RibbonModule {...globalSettings.newsRibbon} className={s["news-ribbon"]} />
                                )}

                                {docType !== "inAppPage" && (
                                    <Header style={headerStyle} globalSettings={globalSettings} pageThemeType={pageThemeType} isMoonriseSite={isMoonriseSite} />
                                )}

                                <main className={cn(s["main"], headerStyle?.includes("transparent") ? s["main--no-top-margin"] : null)}>
                                    {children}
                                </main>


                                {docType !== "inAppPage" && (
                                    <Footer pageReferences={pageReferences} isMoonriseSite={isMoonriseSite} />
                                )}

                                {/* SVG used in global.scss to add gradient to icons */}
                                <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                                    <svg style={{ height: "0px", width: "0px" }}>
                                        <defs>
                                            <linearGradient id="businessColoredSvgGradient" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="0%" stopColor="#00d63c"></stop>
                                                <stop offset="100%" stopColor="#80f64d"></stop>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        )}
                    </ExperimentsProvider>
                </OverwriteProvider>
            </SettingsContext.Provider>
        </BusProvider>
    )
}
